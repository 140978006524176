<template>
  <AModalContent
    class="wrong-network-modal"
    :show-close="false"
  >
    <IInfo
      width="40"
      class="wrong-network-modal-icon"
    />
    <div class="wrong-network-modal-title">
      Your wallet is connected to wrong network
    </div>
    <div class="wrong-network-modal-text">
      Please switch network to
      <b>{{ workingNetwork }}</b>
    </div>
    <div class="wrong-network-modal-actions">
      <a
        href="#"
        class="wrong-network-modal-action wrong-network-modal-action-disconnect"
        @click.prevent="disconnect"
      >Disconnect</a>
      <a
        v-if="showSwitchNetwork"
        href="#"
        class="wrong-network-modal-action wrong-network-modal-action-switch-network"
        @click.prevent="switchNetwork"
      >Switch Network</a>
    </div>
  </AModalContent>
</template>
<script>

import { Config } from "@/config"
import { computed } from "vue-demi"
import AModalContent from "../Modal/AModalContent.vue"
import IInfo from "@/assets/img/info.svg?inline";
import { disconnectWallet, web3 } from "@/web3";
import { useWeb3Store } from "@/store/web3";

export default {
  components: { AModalContent, IInfo },

  setup() {
    const workingNetwork = computed(() => Config.network.name)
    const disconnect = () => {
      disconnectWallet()
    }
    const switchNetwork = async () => {
      try {
        await web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${Config.network.chainId.toString(16)}` }],
        });
      } catch (err) {
        console.error('[Switch Network Error]', err)
      }

    }
    const showSwitchNetwork = computed(() => useWeb3Store().walletType === "MetaMask")
    return { workingNetwork, disconnect, switchNetwork, showSwitchNetwork }
  }

}
</script>
<style lang="scss">
.wrong-network-modal {
  width: 530px;
  min-height: 200px;
  max-width: 90%;
  background-color: var(--gray-light);
  border: 1px solid var(--border);
  padding: 15px;
  box-sizing: border-box;
  border-radius: 10px;
  text-align: center;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &-icon {
    margin-bottom: 10px;
    color: var(--red);
  }
  &-title {
    font-size: 24px;
    font-weight: bold;
    color: var(--red);
  }
  &-text {
    margin-top: 10px;
    font-size: 18px;
    b {
      color: var(--primary);
      text-transform: capitalize;
      font-weight: bold;
    }
  }
  &-actions {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 15px;
    max-width: 80%;
  }
  &-action {
    flex: 1;
    padding: 10px 15px;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    transition: 0.2s;
    font-weight: bold;
    position: relative;
    display: block;
    &:hover {
      filter: brightness(0.8);
    }
    &-switch-network {
      background-color: var(--green);
    }
    &-disconnect {
      background-color: var(--red);
      margin-right: 10px;
    }
  }
  @media screen and (max-width: 576px) {
    &-actions {
      flex-direction: column;
    }
    &-action {
      margin-right: 0 !important;
      width: 100%;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}
</style>